.subscription-card-container {
  background-color: white;
  height: 700px;
  width: 310px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.subscription-card-title {
  margin: auto;
  position: relative;
  top: 10px;
  font-size: 30px;
  width: 65%;
  text-align: center;
}

.subscription-card-title h1 {
  font-size: 28px;
  font-weight: 600;
  width: 100%;
}

.subscription-card-content{
  width:90%;
  margin: auto;
}
.subscription-card-subtitle{
  color: rgb(112, 167, 163);
  text-align: center;
  font-size: 20px;
}
.subscription-card-text{
  color: black;
  text-align: left;
  font-size: 20px;
}

.subscription-card-button {
  background-color: rgb(263,164,93);
  height: 80px;
  width: 100%;
  cursor: pointer;
}
.subscription-card-button:hover{
  background-color: rgb(187,113,53);
}

.subscription-card-button p{
  font-size: 28px;
  margin: 0;
  color: aliceblue;
  text-align: center;
  line-height: 80px;
  font-weight: bold;
}

.subscription-card-text > ul{
  list-style: none; 
  padding: 0;
}

.subscription-card-text > ul > li{
  padding: 6px 2px;
}
