.subscriptions-container{
  width: 90%;
  max-width: 1050px;
  margin: 0 auto;
}

.subscriptions-title{
  color: #E9A45D;
  font-size: 46px;
  margin: 25px 0;
}

.subscriptions-info-container{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.subscriptions-cards-container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,320px);
  grid-gap: 30px;
  justify-content: center;
}

.subscriptions-products-details{
  width: 100%;
  margin: 20px auto;
  margin-bottom: 40px;
}

.products-details-title{
  font-size: 24px;
  margin: 0;
  padding: 10px 20px;
  color: #E9A45D;
  font-weight: 500;
  background-color: white;
}

.products-details-list{
  text-align: left;
  background-color: #7B858B;
  margin: 0;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.details-list-item{
  padding: 10px 10px 10px 0;
  font-size: 18px;
  color: white;
}

@media screen and (min-width: 769px) {
  .subscriptions-container{
    width: 100%;
  }
  .subscriptions-title{
    font-size: 60px;
  }
  .products-details-list{
    font-size: 28px;
  }
  .details-list-item{
    font-size: 26px; 
  }
}
