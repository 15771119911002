.footer{
  width: 100%;
  height: 100px;
  background-color: #70A7A3;
  display: flex;
  align-items: center;
  position: relative;
}

.contact-badge{
  position: absolute;
  color: white;
  font-size: 16px;
  background-color: #072A39;
  height: 30px;
  line-height: 30px;
  top:-30px;
  padding: 0 10px;
  right: 5%;
  cursor: pointer;
}

.footer-container{
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.footer-container > a {
  text-decoration: none;
}

.footer-container-text{
  color: white;
  margin: 0;
  font-size: 20px;
}

@media screen and (min-width: 769px) {
  .footer-container{
    flex-direction: row;
    gap: 30px;
  }
  .contact-badge{
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    top:-50px;
    padding: 0 30px;
  }
}