
.carousel-container{
  height: 100%;
  display: inline-flex;
  overflow: hidden;
}

.carousel-button{
  width: 20px;
  height: 50px;
  position: absolute;
  top: 60%;
  z-index: 20;
  cursor: default;
}

.carousel-button:hover{
  transform: scale(1.1);
}

.b-left{
  left: 20px;
  background-image: url("../../assets/left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.b-right{
  right: 20px;
  background-image: url("../../assets/right.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width:769px) {
  .carousel-button{
    width: 30px;
  }
}