.home-container{
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

@media screen and (min-width:769px){
  .home-container{
    height: 80vh;
  }
}


@media screen and (min-width:1440px){
  .home-container{
    overflow-y: hidden;
  }
}