
.c-card-container {
  border: gainsboro solid 1px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 550px;
}

.c-card-title {
  height: 150px;
  width: 100%;
  background-color: rgb(233, 164, 93);
  display: flex;
  align-items: center;
}

.c-card-title h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: white;
  width: 85%;
  margin: 0 auto;
}

.c-card-image{
  display: flex;
  flex-direction: column;
}

.c-card-content {
  background-color: rgb(83,87,90);
  height: 250px;
  width: 100%;
}

.c-card-content p{
  text-align: center;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  margin: 0;
  font-size: 22px;
  font-weight: 300;
}

.c-card-image img {
  width: 100%;
}

@media screen and (min-width:769px){
  .c-card-content p{
    font-size: 25px;
    line-height: 28px;
  }
}